
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif !important;
}
:root{
  --text-black:#363333;
  --text-black-1:#636363;
  --text-gray:#A2A2A2;
  --text-gray-1:#909090;
  --text-blue:#005FCF;
}
body{
}
.my-0 {
  margin-block: 0 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.w-100 {
  width: 100%;
}
.text-black {
  color: var(--text-black) !important;
}
.text-black-1 {
  color: var(--text-black-1) !important;
}
.text-gray {
  color: var(--text-gray) !important;
}
.text-gray-1 {
  color: var(--text-gray-1) !important;
}
.text-blue {
  color: var(--text-blue) !important;
}
.ant-input-number-input{
  font-weight: 600 !important;
}
.relative {
  position: relative;
}
.segment__position {
  position: absolute;
  top: 10px;
  right: 10px;
  height: calc(100vh - 130px);
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  z-index: 3;
}
.segment__position::-webkit-scrollbar {
  display: none;
}
.segment__card {
  margin-top: 0;
  /* position: absolute;
  top: 30px;
  right: 20px; */
  /*width: 293px;*/
  min-width: 275px;
  font-size: 16px;
  border-radius: 5px !important;
}

.image-style {
  margin-top: -8px;
}
.panel-container {
  border-block: 1px solid #E7E7E7;
  padding: 5px;
  margin-bottom: 5px;
}
.panel-image {
  padding: 5px 5px;
  background-color: #FAFAFA;
  margin: 7px;
}
.app img {
  cursor: grab;
  pointer-events: auto; /* Enable pointer events */
}
.tool_header_container{
  padding: 5px 20px;
}
.m-0 {
  margin: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.text-light {
  color: #f4f4f4 !important;
}
.text-gray-light {
  color: #4E4E4E !important;
}
.gray-to-white img{
  filter: grayscale(100%) brightness(300%) sepia(0%) hue-rotate(0deg);
}
.gray-to-blue img {
  filter: grayscale(100%) brightness(100%) sepia(100%) hue-rotate(190deg) saturate(600%);
}
.black-white{
  filter: brightness(10000%) invert(1) grayscale(0%) sepia(0%) hue-rotate(0deg);
}
.h-100 {
  height: 100vh;
}

/* BubbleLoader.css */
.bubble-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubble-loader {
  display: flex;
  align-items: center;
}

.bubble {
  width: 20px;
  height: 20px;
  background-color: #0070d8;
  /* You can change the color */
  border-radius: 50%;
  margin-left: 20px;
  animation: bubbleAnimation 3s ease-in-out infinite;
  opacity: 0;
}

.bubble:nth-child(1) {
  animation-delay: 0.2s;
}

.bubble:nth-child(2) {
  animation-delay: 0.4s;
}

.bubble:nth-child(3) {
  animation-delay: 0.6s;
}

.bubble:nth-child(4) {
  animation-delay: 0.8s;
}

.bubble:nth-child(5) {
  animation-delay: 1s;
}

@keyframes bubbleAnimation {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  50% {
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.progress-bar-container {
  width: 100%;
  height: 15px;
  background-color: #f4f4f4;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #a0a0a0;
  /* Default color */
  transition: width 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.progress-bar-container.complete .progress-bar {
  background-color: #0070d8;
  /* Color when progress is complete */
}

.progress-text {
  color: #fff;
  font-size: 12px;
  /* You can change the color */
  margin-right: 10px;
}

.progress-bar-container.complete .progress-text {
  color: #0070d8;
  font-size: 10px;
  /* Color when progress is complete */
}

/* ProfessionalProgressBar.css */
/* ProfessionalProgressBar.css */
.professional-progress-bar-container {
  width: 100%;
  height: 30px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  margin: 20px 0;
  position: relative;
}

.professional-progress-bar {
  height: 100%;
  position: relative;
}

.progress-bar-fill {
  height: 100%;
  background-color: #3498db;
  /* Default color */
  transition: width 0.3s ease-in-out;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.professional-progress-bar-container.complete .progress-bar-fill {
  background-color: #0070d8;
  /* Color when progress is complete */
}

.bubbles-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}


.professional-progress-bar-container.complete .bubble {
  background-color: #0070d8;
  /* Color of bubbles when progress is complete */
}

.progress-text {
  color: #555;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.professional-progress-bar-container.complete .progress-text {
  color: #0070d8;
  /* Text color when progress is complete */
}

.complete .progress-bar-fill {
  width: 100%;
}
.loader-container{
  min-height: 100vh;
  /*height :calc(100vh - 300px);*/
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(26, 22, 22);
}
.loader {
  color: #fff;
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 
    2em -2em 0 0em, 3em 0 0 -1em, 
    2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 -1em, -3em 0 0 -1em, 
    -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 
    3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 -1em, -3em 0 0 -1em, 
    -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 
    2em -2em 0 0, 3em 0 0 0.2em, 
    2em 2em 0 0, 0 3em 0 -1em, 
    -2em 2em 0 -1em, -3em 0 0 -1em, 
    -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
     3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, 
     -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
     3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, 
     -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
     3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, 
     -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 
    3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 
    3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
    -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
  
.input_number {
  width: 57px !important;
}
.image__list{
  position: relative;
}
.image__name{
  position: absolute;
  display: block;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 3px;
  width: 100%;
  z-index: 10;
  color: white;
  text-align: center;
}

.delete-icon {
  font-size: 16px;
  color: #A9A9A9; /* Set initial grey color */
  transition: color 0.3s ease, transform 0.3s ease;
}
.edit-icon {
  font-size: 16px;
  color: #A9A9A9; /* Set initial grey color */
  transition: color 0.3s ease, transform 0.3s ease;
}
.edit-icon:hover {
  color: #0e529e; /* Change to your desired hover color */
  transform: scale(1.1); /* Optional: Add a slight zoom effect */
}

.delete-button:hover .delete-icon {
  color: #CD5C5C; /* Change icon color to red on hover */
}
.delete-icon:hover {
  color: #CD5C5C; /* Change to your desired hover color */
  transform: scale(1.1); /* Optional: Add a slight zoom effect */
}
.ant-collapse .ant-collapse-content>.ant-collapse-content-box{
  padding: 0px;
}

.ant-collapse-ghost >.ant-collapse-item >.ant-collapse-content >.ant-collapse-content-box {
  padding-block: 0px
}

.show-tile{
  height:revert;
  font-size: 12px;
}
.ant-btn-default, .ant-btn-default:hover {
 background: transparent !important;
}
.ant-btn, .ant-select-selector, .ant-input-number {
  border-radius: 3px !important;
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header {
  padding: 6px 14px;
  align-items: center;
}

.segment-name{
  font-weight: 300;
  font-size: 13px;
}
.obs-name{
  font-size: 11px;
}

.card-header{
  font-size: 16px;
  font-weight: 400;
}
.ant-card .ant-card-head {
  min-height: 45px;

}

.custom-scrollbar::-webkit-scrollbar, .custom-scrollbar-dark::-webkit-scrollbar {
  width: 6px;
}
.custom-scrollbar::-webkit-scrollbar-track, .custom-scrollbar-dark::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.custom-scrollbar-dark::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.input-container {
  position: absolute;
  z-index: 999;
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 4px;
  background-color: #fff;
}

.custom-input {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  width: 20px; /* Adjust width as needed */
  text-align: right;
}

.custom-input::-webkit-outer-spin-button,
.custom-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.unit-label {
  margin-left: 4px;
  font-size: 14px;
  color: #555;
}

/* Optional: For better user experience */
.custom-input:focus {
  box-shadow: none;
}
.add-button {
  background-color: #ffffff; /* White background */
  /*border: none; !* Remove default button border *!*/
  border: 1px solid #858383 !important;
  padding: 1px !important;
}

.add-button:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
  /*border: 1px solid #605e5e !important;*/
}

.add-button .anticon svg {
  fill: #000000; /* Black color for the + sign */
}

.ant-form-item {
  margin-bottom: 0;
  /* font-size: small !important; */
}

.ant-form-item-label {
  padding: 0 !important;
}

.ant-form-item-label>label {
  min-width: 100%;
}

.ant-form-item-label>label, .ant-form .ant-col {
  color: #858383 !important;
  font-size: 11px !important;
}

.ant-select-selector {
  height: auto !important;
}

.ant-select-selection-item {
  white-space: normal !important;
  word-break: break-word;
  line-height: 1.5 !important;
  padding: 4px 0 !important;
  font-size: 14px;
}

.ant-select-item-option-content{
  white-space: normal !important;
  word-break: break-word;
}

.ant-input-number {
  width: 100%;
}

.ant-input-number-input {
  font-weight: 400 !important;
}

@media screen and (max-height: 600px) {
  .ant-form {
    height: 175px;
  }
}

.info {
  margin-right: -10px;
}

.info:hover {
  color: var(--text-blue);
}

.ant-modal-content {
  border-radius: 5px !important;
}

@keyframes pulse {
  0%, 100% {
    border-color: #d9d9d9;
  }
  50% {
    border-color: var(--text-blue);
    box-shadow: 0 0 0 2px rgba(0, 95, 207, 0.2);
  }
}

#pulse-button {
  animation: pulse 2s infinite;
}

#pulse-button:hover {
  animation: none;
}